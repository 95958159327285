import React from 'react';
import './style.css';

function App() {
  return (
    <>
      <div className="content">
        <video autoPlay muted loop poster="./image.jpg">
          <source src="./video.mp4" type="video/mp4" />
          <img src="./image.jpg" alt="flower" />
        </video>
        <div className="logo--section">
          <img src="./logo.png" alt="logo" />
          <a href="#">
            <button className="button-catalog">Próximamente</button>
          </a>
        </div>
      </div>
    </>
  );
}

export default App;
